import {constructServerSideProps} from 'utils/ssr';
import {PartnerMetrics} from 'modules/PartnerMetrics';
import {PartnerInfo} from '@halp/foundation/Partners';
import {Stack} from '@halp/ui';
import {useUser, withUserProtectedRoute} from '@halp/foundation/Users';

function PartnerIndex() {
	const {user} = useUser();

	if (!user?.partnerProfile) {
		return null;
	}

	return (
		<Stack
			direction="column"
			alignItems="flex-start"
			justifyContent="flex-start"
			spacing="lg"
		>
			<PartnerInfo
				businessName={user.partnerProfile.businessName}
				name={user?.name}
				email={user?.email}
			/>
			<PartnerMetrics />
		</Stack>
	);
}

export default withUserProtectedRoute(PartnerIndex, {role: 'partner'});
export const getServerSideProps = constructServerSideProps();
