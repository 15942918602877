import type {SurfaceColor} from '@halp/ui/Surface/type';
import type {Color as TypographyColor} from '@halp/ui/Typography/types';

type Color = SurfaceColor & TypographyColor;

export const METRIC_COLORS: {[key in string]: Color} = {
	signup: 'blurple',
	verification: 'blue',
	getStarted: 'pink',
	discover: 'red',
	prep: 'orange',
	submit: 'yellow',
	accepted: 'green',
	visa: 'blurple',
	setUp: 'blue',
	placed: 'red',
};

export const METRIC_TITLES: {[key in string]: string} = {
	signup: 'Sign Ups',
	verification: 'Verifications',
	getStarted: 'Get Started',
	discover: 'Discover',
	prep: 'Prep',
	submit: 'Submit',
	accepted: 'Accepted',
	visa: 'Visa',
	setUp: 'Set Up',
	placed: 'Placed',
};

export const FUNNEL_STAGE_ORDER = [
	'signup',
	'verification',
	'getStarted',
	'discover',
	'prep',
	'submit',
	'accepted',
	'visa',
	'setUp',
	'placed',
];
